

.livenext-player {
  background: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.livenext-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  z-index: 10;
}

.livenext-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.livenext-wrapper .livenext-player__page {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.livenext-wrapper .livenext-player__image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.livenext-wrapper .livenext-player__video {
  width: 100%;
  height: 100%;
  position: relative;
}

.livenext-wrapper .livenext-player__video video {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
}

.livenext-player .livenext-manual {
  width: 100%;
  bottom: calc(-150px);
  position: fixed;
  z-index: 10;
  display: flex;
  height: 200px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 15px;
  box-sizing: border-box;
  transition: 300ms;

}

.livenext-player .livenext-manual .livenext-manual-relative-viewes {
  max-width: 1000px;
  height: 200px;
  transform: translate(0px, 100%);
  flex: 1;
  position: relative;
  transition: 300ms;
}

.livenext-player .livenext-manual:hover {
  bottom: 0px;
  transition: 300ms;
}

.livenext-player .livenext-manual:hover > .livenext-manual-relative-viewes {
  transform: translate(0px, 0px);
  transition: 300ms;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-relative-viewes-scroll {
  top: 50%;
  transform: translate(0px, -50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: absolute;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-subcontrol {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 40px;
  aspect-ratio: 1;
  background: #0E0E0E;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 1;
  filter: drop-shadow(0px 0px 50px 50px black);

}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-subcontrol:hover {
  cursor: pointer;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-subcontrol-l {
  left: 0;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-subcontrol-r {
  right: 0;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-relative-viewes-scroll .livenext-manual-page {
  width: 265px;
  height: 150px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 1px solid rgba(255, 255, 255, 0);
  outline-offset: 0px;
  transition: 300ms;
}


.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-relative-viewes-scroll .livenext-manual-page:hover {
  cursor: pointer;
  outline: 1px solid rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.0);
  outline-offset: 5px;
  scale: 1.05;
  transform: translate(0px, -5px);
  transition: 300ms;
}

.livenext-player .livenext-manual .livenext-manual-relative-viewes .livenext-manual-relative-viewes-scroll .livenext-manual-page h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}


.livenext-controller {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
}

.livenext-controller .livenext-controller-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 25px;
}

.livenext-controller .livenext-controller-header svg {
  height: 50px;
}

.livenext-controller .livenext-controller-groups {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  align-self: stretch;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room h3 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  align-self: stretch;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room h5 {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.25);
  align-self: stretch;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px;
  gap: 10px;
  align-self: stretch;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard .livenext-controller-keyboard-p {
  flex: 1;
  min-width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid rgba(134, 191, 242, 0.15);
  box-shadow: inset 0px 0px 48px rgba(199, 211, 234, 0.05), inset 0px 0px 1px rgba(199, 211, 234, 0.12);
  border-radius: 12px;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard .livenext-controller-keyboard-p .loader {
  width: 15px;
  aspect-ratio: 1;
  background-image: url('../assets/loader.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard .livenext-controller-keyboard-p .loader-on {
  animation: linear infinite smartrotation 1s;
}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard .livenext-controller-keyboard-p .loader-off {

}

.livenext-controller .livenext-controller-groups .livenext-controller-room .livenext-controller-keyboard .livenext-controller-keyboard-p p {
  font-family: Altima;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  align-self: stretch;
  user-select: none;
}